import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index.js";
import Vant from 'vant'
import 'vant/lib/index.css';
import store from "./store/index.js";
import './assets/css/reset.less';
import 'amfe-flexible'
// 引入Quill样式
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

const app = createApp(App);


app.use(router).use(Vant).use(store).mount('#app')